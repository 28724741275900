define("discourse/plugins/discourse-events/discourse/controllers/admin-plugins-events-event", ["exports", "@ember/array", "@ember/controller", "@ember/object/computed", "@ember/service", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-events/discourse/components/modal/events-confirm-event-deletion", "discourse/plugins/discourse-events/discourse/components/modal/events-connect-topic", "discourse/plugins/discourse-events/discourse/mixins/message", "discourse/plugins/discourse-events/discourse/models/event"], function (_exports, _array, _controller, _computed, _service, _decorators, _I18n, _eventsConfirmEventDeletion, _eventsConnectTopic, _message, _event) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(_message.default, dt7948.p({
    hasEvents: (0, _computed.notEmpty)("events"),
    selectedEventIds: (0, _array.A)(),
    modal: (0, _service.inject)(),
    selectAll: false,
    order: null,
    asc: null,
    filter: null,
    queryParams: ["filter"],
    addDisabled: (0, _computed.not)("subscription.subscribed"),
    subscription: (0, _service.inject)("events-subscription"),
    router: (0, _service.inject)(),
    deleteDisabled(selectedEventIds, hasEvents) {
      return !hasEvents || !selectedEventIds.length;
    },
    selectDisabled(hasEvents) {
      return !hasEvents;
    },
    noneLabel(filter) {
      return _I18n.default.t(`admin.events.event.none.${filter === "connected" ? "connected" : "unconnected"}`);
    },
    eventsRoute(currentRouteName) {
      return currentRouteName === "adminPlugins.events.event.index";
    },
    unconnectedRoute(eventsRoute, filter) {
      return eventsRoute && filter === "unconnected";
    },
    connectedRoute(eventsRoute, filter) {
      return eventsRoute && filter === "connected";
    },
    viewName(eventsRoute, filter) {
      return `event.${filter}`;
    },
    connectTopicDisabled(selectedEventIds) {
      return selectedEventIds.length !== 1;
    },
    updateTopicDisabled(selectedEventIds) {
      return selectedEventIds.length !== 1;
    },
    updateCurrentRouteCount() {
      const events = this.get("events");
      this.set(`${this.unconnectedRoute ? "without" : "with"}TopicsCount`, events.length);
    },
    actions: {
      openConnectTopic() {
        const selectedEventId = this.selectedEventIds[0];
        const event = this.get("events").findBy("id", selectedEventId);
        if (!event) {
          return;
        }
        this.modal.show(_eventsConnectTopic.default, {
          model: {
            event,
            onConnectTopic: () => {
              this.set("selectedEventIds", (0, _array.A)());
              this.get("events").removeObject(event);
            }
          }
        });
      },
      updateTopic() {
        const selectedEventId = this.selectedEventIds[0];
        const event = this.get("events").findBy("id", selectedEventId);
        if (!event) {
          return;
        }
        this.set("updating", true);
        _event.default.updateTopic({
          event_id: event.id
        }).then(result => {
          if (result.success) {
            this.set("selectedEventIds", (0, _array.A)());
          }
        }).finally(() => this.set("updating", false));
      },
      modifySelection(eventIds, selected) {
        this.get("events").forEach(event => {
          if (eventIds.includes(event.id)) {
            event.set("selected", selected);
          }
        });
        if (selected) {
          this.get("selectedEventIds").addObjects(eventIds);
        } else {
          this.get("selectedEventIds").removeObjects(eventIds);
        }
      },
      openDelete() {
        var _this = this;
        this.modal.show(_eventsConfirmEventDeletion.default, {
          model: {
            eventIds: this.selectedEventIds,
            onDestroyEvents: function () {
              let destroyedEventIds = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
              let destroyedTopicsEvents = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
              _this.set("selectedEventIds", (0, _array.A)());
              const events = _this.get("events");
              if (destroyedEventIds) {
                const destroyedEvents = events.filter(e => destroyedEventIds.includes(e.id));
                events.removeObjects(destroyedEvents);
                _this.updateCurrentRouteCount();
              }
              if (destroyedTopicsEvents) {
                const destroyedTopicsEventIds = destroyedTopicsEvents.map(e => e.id);
                events.forEach(event => {
                  if (destroyedTopicsEventIds.includes(event.id)) {
                    event.set("topics", null);
                  }
                });
              }
            }
          }
        });
      }
    }
  }, [["method", "deleteDisabled", [(0, _decorators.default)("selectedEventIds.[]", "hasEvents")]], ["method", "selectDisabled", [(0, _decorators.default)("hasEvents")]], ["method", "noneLabel", [(0, _decorators.default)("filter")]], ["method", "eventsRoute", [(0, _decorators.default)("router.currentRouteName")]], ["method", "unconnectedRoute", [(0, _decorators.default)("eventsRoute", "filter")]], ["method", "connectedRoute", [(0, _decorators.default)("eventsRoute", "filter")]], ["method", "viewName", [(0, _decorators.default)("eventsRoute", "filter")]], ["method", "connectTopicDisabled", [(0, _decorators.default)("selectedEventIds.[]")]], ["method", "updateTopicDisabled", [(0, _decorators.default)("selectedEventIds.[]")]]]));
});